<template>
  <div>
    <router-view></router-view>
    <dashboard-page-title :showMainActions="false" :showSearch="false" :showFilter="false">إعدادات السائق</dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div>
          <b-row class="align-items-center">
            <b-col lg="3">
              <h4 class="">السماح للسائق بالتسجيل</h4>
            </b-col>
            <b-col lg="2">
              <div class="d-flex align-items-center">
                <b-form-radio
                    class="radio-payment-actions custom-checkbox-color color-danger d-flex align-items-center"
                    v-model="drivers_can_register"
                    name="plain-inline"
                    plain
                    color="primary"
                    value="true"
                >
                  <div class="d-flex flex-column mr-3 pr-3 py-3">
                    <p class="font-size-18 m-0 mr-2 p-0 text-muted">نعم</p>
                  </div>
                </b-form-radio>
              </div>
            </b-col>
            <b-col lg="2">
              <div class="d-flex align-items-center">
                <b-form-radio
                    class="radio-payment-actions custom-checkbox-color color-danger d-flex align-items-center"
                    v-model="drivers_can_register"
                    name="plain-inline"
                    plain
                    color="primary"
                    value="false"
                >
                  <div class="d-flex flex-column mr-3 pr-3 py-3">
                    <p class="font-size-18 m-0 mr-2 p-0 text-muted">لا</p>
                  </div>
                </b-form-radio>
              </div>
            </b-col>
          </b-row>
          <b-row class="align-items-center">
            <b-col lg="3">
              <h4 class="ml-5">العمولة من السائق</h4>
            </b-col>
            <b-col lg="2">
              <div class="d-flex align-items-center">
                <b-form-radio
                    class="radio-payment-actions custom-checkbox-color color-danger d-flex align-items-center"
                    v-model="drivers_commission_type"
                    name="plain-inline-name"
                    plain
                    color="primary"
                    value="number"
                >
                  <div class="d-flex flex-column mr-3 pr-3 py-3">
                    <p class="font-size-18 m-0 mr-2 p-0 text-muted">مبلغ ثابت</p>
                  </div>
                </b-form-radio>
              </div>
            </b-col>
            <b-col lg="2">
              <div class="d-flex align-items-center">
                <b-form-radio
                    class="radio-payment-actions custom-checkbox-color color-danger d-flex align-items-center"
                    v-model="drivers_commission_type"
                    name="plain-inline-name"
                    plain
                    color="primary"
                    value="percentage"
                >
                  <div class="d-flex flex-column mr-3 pr-3 py-3">
                    <p class="font-size-18 m-0 mr-2 p-0 text-muted">نسبة مئوية</p>
                  </div>
                </b-form-radio>
              </div>
            </b-col>
            <b-col v-if="drivers_commission_type === 'number'" lg="3">
              <div class="d-flex align-items-center">
                <input-form class="m-0" placeholder="المبلغ" :validate="'required|numeric'" v-model="amount"/>
                <p class="m-0 p-0 font-size-18 mr-3">ريال سعودى</p>
              </div>
            </b-col>
            <b-col v-else lg="3">
              <div class="d-flex align-items-center">
                <input-form class="m-0" placeholder="النسبة" :validate="'required|numeric'" v-model="amount"/>
                <p class="m-0 p-0 font-size-18 mr-3">%</p>
              </div>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center my-3">
            <b-button variant="primary" type="submit" class="px-5 py-2 iq-border-radius-5 text-center">
              <span class="mx-2 font-size-18"> حفظ التعديلات</span>
            </b-button>
          </div>
        </div>
        <hr class="w-75">
      </form>
    </ValidationObserver>
    <div class="d-flex align-items-center justify-content-between my-3">
      <p class="text-muted font-size-22 font-weight-bold p-0 m-0">الأوراق المطلوبة من السائق عند التسجيل</p>
      <router-link class="cursor-pointer" :to="{name: 'addRequired', query: { type: 'driver' }}">
        <b-button
            class="px-5 py-2 ml-5 d-flex align-items-center border-0 add-btn-settings">
          <span class="text-primary font-size-18">+ اضافة</span>
        </b-button>
      </router-link>
    </div>
    <div class="d-flex align-items-center mb-5">
      <service-box v-for="(item, index) in services" :key="index" :item="item" />
    </div>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import serviceBox from '@/modules/transportation/components/serviceBox'
import transportationService from '@/modules/transportation/services/transportation'
export default {
  name: 'driversSettings',
  components: { serviceBox },
  data () {
    return {
      drivers_can_register: null,
      drivers_commission_type: '',
      amount: '',
      filter: {
        type: 'driver'
      },
      services: []
    }
  },
  methods: {
    driversDocuments () {
      transportationService.requiredDocument(this.filter.type).then(res => {
        this.services = res.data.data
      })
    },
    getSettings () {
      transportationService.getAllSettings().then((res) => {
        this.drivers_can_register = res.data.data.drivers_can_register
        this.drivers_commission_type = res.data.data.drivers_commission_type
        this.amount = res.data.data.drivers_commission
      })
    },
    onSubmit () {
      transportationService.addDriverSetting({ drivers_can_register: Boolean(this.drivers_can_register.value), drivers_commission_type: this.drivers_commission_type, drivers_commission: parseInt(this.amount) }).then((res) => {
        core.showSnackbar('success', res.data.message)
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getSettings()
    this.driversDocuments()
  }
}
</script>

<style>
input[name*="plain-inline"] {
  accent-color: green;
}
.add-btn-settings{
  background-color: #2B58421A !important;
}
</style>
