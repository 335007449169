<template>
<div>
<!--  {{item}}-->
<!--  {{selectedService}}-->
  <div class="service-box d-flex align-items-center m-3 py-2 px-3" :class="{'selected': item.status }" @click="handleSelected(item) ; changeStatus(item.id)">
    <span class="check-box m-0 p-0" :class="{'selected': item.status}"></span>
    <p class="font-size-16 m-0 mr-3 p-0" v-text="item.name"></p>
  </div>
</div>

</template>

<script>
import transportationService from '@/modules/transportation/services/transportation'
import { core } from '@/config/pluginInit'
export default {
  props: ['item'],
  data () {
    return {
      selectedService: '',
      featuresIsOpened: false
    }
  },
  methods: {
    handleSelected (item) {
      if (item.status) {
        item.status = 0
        console.log(item.status)
      } else {
        item.status = 1
        console.log(item.status, this.item.id)
      }
    },
    changeStatus (id) {
      transportationService.editStatus(id).then(() => {
        core.showSnackbar('success', 'تم حفظ التعديلات')
      }).finally(() => {

      })
    }
  }

}
</script>
<style>
.service-box {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  border-radius: 10px;
  cursor: pointer;
  border: 4px solid transparent;
  transition: .5s;
}
.check-box{
  width: 10px;
  height: 10px;
  border: 1px solid var(--iq-secondary-dark);
}
.check-box.selected{
  border-color: #6EBF97;
  background-color: #6EBF97;
}
.service-box.selected {
  border-color: #6EBF97;
}
/*.service-box-title {*/
/*  color: #646464 !important;*/
/*}*/
</style>
